<template>
  <div class="flex gap-24">
    <partner
      v-if="isStovePrecession"
      :precessionList="precessionList"
      :precessionDetails="precession"
    />
    <div v-else class="flex-1">
      <st-box>
        <st-form-title :formTitle="$t('studio.prod_home.pur_cond.pur_cond_option')" />
        <radio-group
          name="usage"
          :disabled="!isProductWritable"
          :optionProps="{ size: 'sm', align: 'middle' }"
          :options="usageOptions"
          size="sm"
          class="flex flex-wrap gap-x-24 mt-12"
        />
        <st-form-title
          :formTitle="$t('studio.prod_home.pur_cond.pur_cond_prod')"
          count
          :currentCount="precessionProductNoList.length"
          :maxCount="MAX_PRODUCT_COUNT"
          class="mt-32"
        />
        <div
          class="mt-20 flex h-220 flex-col gap-4 rounded-2xl bg-[var(--stds-glob-color-gray40)] pt-16"
        >
          <div class="shrink-0 px-20">
            <input-text
              v-model="productSearchText"
              name="productSearchText"
              :size="'lg'"
              :placeholder="$t('studio.prod_home.pur_cond.pur_cond_prod_place_holder')"
              :searchable="true"
              :rules="{
                max_length: {
                  length: 100,
                  message: $t('studio.common.def_key.exceed', { length: 100 })
                }
              }"
              :disabled="!isProductWritable"
              :countable="false"
              maxLength="100"
              @search="onSearch"
              @clear="onClear"
            />
            {{ valueSearch }}
          </div>
          <div
            v-end-scroll="loadMore"
            class="flex-1 pl-20"
            :class="{ 'studio-scrollbar-4 flex-1 pl-20': searchResult.length > 0 }"
          >
            <checkbox-group
              :key="precessionProductNoList.length"
              name="precessionProductNoList"
              :options="searchResult"
              class="flex flex-col gap-4 pt-8"
              :disabled="!isProductWritable"
              size="sm"
              :optionProps="{ align: 'middle', size: 'sm', class: 'py-4' }"
            >
              <template #default="{ value, label }">
                <s-checkbox
                  :value="value"
                  size="sm"
                  align="middle"
                  :isDisabled="
                    precessionProductNoList.length >= MAX_PRODUCT_COUNT && !precessionProductNoList.includes(value as string)
                  "
                  @update:modelValue="onCheckboxClick($event, value)"
                >
                  <st-tooltip-text-over position="bottom-start" hasArrow :content="`${$t(label)}`">
                    <span class="break-all line-clamp-1 z-[10]">{{ `${$t(label)}` }}</span>
                  </st-tooltip-text-over>
                </s-checkbox>
              </template>
            </checkbox-group>
            <p
              v-if="searchResult.length === 0"
              class="flex h-full min-h-156 items-center justify-center pt-16 pb-32 text-center text-sm leading-md text-on-surface-elevation-2"
            >
              <!-- SEARCH BUT NO RESULT  -->
              <template v-if="isProductAvailable">
                {{ $t('studio.prod_home.pur_cond.pur_cond_prod_no_result') }}
              </template>
              <!-- NO PRODUCTS AVAILABLE  -->
              <template v-else>
                {{ $t('studio.prod_home.pur_cond.pur_cond_prod_none') }}
              </template>
            </p>
          </div>
        </div>
        <span
          v-if="isErrorMessageShown && isSubmitting"
          class="mt-4 flex gap-2 text-xs leading-xs text-error"
          as="p"
        >
          {{ $t('studio.prod_home.pur_cond.select_prod_req_msg') }}
        </span>
        <selectable-tag-list
          v-show="precessionProductNoList.length"
          class="pt-16"
          name="precessionProductNoList"
          isDeletableTag
          :tagList="searchResult"
          isTagAlwaysShown
          :labelList="labelList"
          @remove="onRemove"
        >
          <template #chipSlot="{ value }">
            <span class="line-clamp-1 break-all text-left">{{ value.label }}</span>
          </template>
        </selectable-tag-list>

        <st-form-title :formTitle="$t('studio.prod_home.pur_cond.pur_cond_type')" class="mt-32" />
        <radio-group
          name="precessionType"
          :optionProps="{ size: 'sm', align: 'middle' }"
          :options="conditionOptions"
          size="sm"
          class="flex flex-wrap gap-x-24 mt-12"
        />
      </st-box>
      <div class="mt-40 flex justify-center gap-16">
        <s-button variant="primary" size="lg" class="!min-w-160" @click="submitForm">
          {{
            product?.planStatus === PLAN_STATUS.RELEASE
              ? $t('studio.common.live_apply_btn')
              : $t('studio.common.popup_case_save_btn')
          }}
        </s-button>
      </div>
    </div>
    <right-wing>
      <right-wing-item
        :rightWingTitle="$t('studio.prod_home.pur_cond.pur_cond_guide5')"
        numberValue="01"
      >
        {{ $t('studio.prod_home.pur_cond.pur_cond_guide5_detail') }}
      </right-wing-item>
      <right-wing-item
        v-if="isStovePrecession"
        :rightWingTitle="$t('studio.prod_home.pur_cond.pur_cond_guide4')"
        numberValue="02"
        class="mt-24"
      >
        <safe-html tag="span" :html="$t('studio.prod_home.pur_cond.pur_cond_guide4_detail')" />
      </right-wing-item>
      <right-wing-item
        v-else
        :rightWingTitle="$t('studio.prod_home.pur_cond.pur_cond_guide1')"
        numberValue="02"
        class="mt-24"
      >
        <safe-html tag="span" :html="$t('studio.prod_home.pur_cond.pur_cond_guide1_detail')" />
      </right-wing-item>
      <right-wing-item
        :rightWingTitle="$t('studio.prod_home.pur_cond.pur_cond_guide2')"
        numberValue="03"
        class="mt-24"
      >
        {{ $t('studio.prod_home.pur_cond.pur_cond_guide2_detail') }}
      </right-wing-item>
      <right-wing-item
        :rightWingTitle="$t('studio.prod_home.pur_cond.pur_cond_guide3')"
        numberValue="04"
        class="mt-24"
      >
        {{ $t('studio.prod_home.pur_cond.pur_cond_guide3_detail') }}
      </right-wing-item>
    </right-wing>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useFieldValue, useForm, useSetFieldValue } from 'vee-validate';
import { computed, ref, shallowRef, watch } from 'vue';
import { onBeforeRouteLeave, useRoute } from 'vue-router';

import { definePageMeta, useProduct } from '@/.nuxt/imports';
import { fetchProductsInfoApi, searchProductsPrecessionApi } from '@/apis/products.api';
import {
  createPrecessionApi,
  fetchPrecessionApi,
  updatePrecessionApi
} from '@/apis/purchase-condition.api';
import RightWing from '@/components/app/right-wing/index.vue';
import RightWingItem from '@/components/app/right-wing/item.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import SelectableTagList from '@/components/common/selectable-tag-list.vue';
import StBox from '@/components/common/st-box.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import StTooltipTextOver from '@/components/common/st-tooltip-text-over.vue';
import Partner from '@/components/purchase-condition/partner.vue';
import ReleaseDialog from '@/components/terms/release-dialog.vue';
import CheckboxGroup from '@/components/validation/checkbox-group.vue';
import InputText from '@/components/validation/input-text.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import { useApp } from '@/composables/useApp';
import { showDialog } from '@/composables/useDialog';
import { PRODUCT_TITLE } from '@/constants/page.const';
import { ROLE_ID } from '@/constants/permission.const';
import {
  COLLECTION_PRODUCT_TYPE,
  PLAN_STATUS,
  PRODUCT_CONDITION_TYPE,
  PRODUCT_DETAIL_TYPE,
  PRODUCT_FEAT_NAME
} from '@/constants/products.const';
import { Confirmation } from '@/enums/common.enum';
import useProductStore from '@/stores/product.store';
import type { FormOption, FormOptionGroup } from '@/types/common/form.type';
import type { ProductParentChild, ProductSearchItem } from '@/types/product/product-response.type';
import type { CreatePrecessionRequest } from '@/types/purchase-condition/purchase-condition.request.type';
import type {
  PrecessionDetails,
  PrecessionResponse
} from '@/types/purchase-condition/purchase-condition.response.type';
import { showConfirmLeaveDialog } from '@/utils/common.util';

const MAX_PRODUCT_COUNT = 10;

const { checkProductPermission } = useApp();

const route = useRoute();

const productComposable = useProduct();
const { showCompleteSaveDialog } = productComposable;

definePageMeta({
  middleware: 'handle-product-data-middleware',
  roleAuthorityId: ROLE_ID.PRODUCT_SALE_CONDITION,
  productFeat: PRODUCT_FEAT_NAME.PURCHASE_CONDITION,
  pageTitle: PRODUCT_TITLE.PURCHASE_CONDITION
});

const productStore = useProductStore();

const { isProductWritable, product } = storeToRefs(productStore);

const conditionOptions = ref<FormOptionGroup[]>([
  {
    value: PRODUCT_CONDITION_TYPE.AND,
    label: 'studio.prod_home.pur_cond.pur_cond_type1',
    isDisabled: true
  },
  {
    value: PRODUCT_CONDITION_TYPE.OR,
    label: 'studio.prod_home.pur_cond.pur_cond_type2',
    isDisabled: true,
    tooltip: 'studio.prod_home.pur_cond.pur_cond_type2_tooltip'
  }
]);

const usageOptions = ref<FormOptionGroup[]>([
  {
    value: Confirmation.YES,
    label: 'studio.prod_home.pur_cond.pur_cond_option_y'
  },
  {
    value: Confirmation.NO,
    label: 'studio.prod_home.pur_cond.pur_cond_option_n'
  }
]);

const currentPage = ref<number>(1);
const isProductAvailable = ref<boolean>(false);
const canLoadMore = ref<boolean>(true);
const valueSearch = ref<string>('');
const productSearchText = ref<string>('');
const totalPage = ref<number>(0);
const isEditMode = ref<boolean>(false);
const isSubmitting = ref<boolean>(false);

const labelList = ref<FormOption<string>[]>([]);

const precession = ref<PrecessionResponse>();
const precessionList = ref<PrecessionDetails[]>([]);
const precessionSearchResults = ref<ProductSearchItem[]>([]);

const { handleSubmit, setValues, values } = useForm<{
  precessionProductNoList: string[];
  precessionType: keyof typeof PRODUCT_CONDITION_TYPE;
  usage: Confirmation.YES | Confirmation.NO;
}>({
  initialValues: {
    precessionProductNoList: [],
    precessionType: PRODUCT_CONDITION_TYPE.AND,
    usage: Confirmation.NO
  }
});

const precessionProductNoList = useFieldValue<string[]>('precessionProductNoList');
const setConditionType = useSetFieldValue<string>('precessionType');

const groupId = computed(() => route.params.groupId as string);
const productNo = computed(() => route.params.productId as string);

const isErrorMessageShown = computed(() => {
  return precessionProductNoList.value.length === 0 && values.usage === Confirmation.YES;
});

const isStovePrecession = computed<boolean>(
  () => precession.value?.viewOnlyYn === Confirmation.YES
);

const searchResult = computed<FormOption<string>[]>(() => {
  const isProductDLC = product.value?.productDetailType === PRODUCT_DETAIL_TYPE.DLC;
  const allDLCs = product.value?.childDlcProducts ?? [];

  /*
    If the product you are currently setting the purchase conditions for is a parent product that has DLCs, the DLCs will not be displayed in the list.
    If the product you are currently setting the purchase conditions for is a DLC, the parent product will not be displayed in the list.
  */
  const result = precessionSearchResults.value
    .filter(
      (item: ProductSearchItem) =>
        item.productNo?.toString() !== productNo.value &&
        (isProductDLC ? item.productNo !== product.value?.parentProduct?.productNo : true) &&
        !allDLCs.find((i: ProductParentChild) => i.productNo === item.productNo)
    )
    .map((item: ProductSearchItem) => ({
      value: item.productNo.toString(),
      label:
        item.productType !== COLLECTION_PRODUCT_TYPE.COLLECTION
          ? `${item.projectName} - ${item.productName} (${item.productNo})`
          : `${item.productName} (${item.productNo})`
    }));

  return result;
});

const onClear = async () => {
  await onSearch();
  isProductAvailable.value = precessionSearchResults.value.length > 0;
};

const onSearch = async () => {
  if (productSearchText.value.length > 100) {
    return;
  }

  currentPage.value = 1;

  const results = await searchProductsPrecessionApi(Number(productNo.value), {
    groupId: groupId.value,
    q: productSearchText.value,
    planStatus: [PLAN_STATUS.RELEASE],
    productDetailType: `${PRODUCT_DETAIL_TYPE.BASIC},${PRODUCT_DETAIL_TYPE.SOUND},${PRODUCT_DETAIL_TYPE.IMAGE},${PRODUCT_DETAIL_TYPE.VIDEO},${PRODUCT_DETAIL_TYPE.DLC}`,
    demoYn: Confirmation.NO
  });
  if (!results) {
    return;
  }
  if (precessionSearchResults.value.length === 0) {
    isProductAvailable.value = productSearchText.value !== '';
  }
  precessionSearchResults.value = results.contents;
};

const submitForm = handleSubmit(
  async (value: {
    precessionProductNoList: string[];
    precessionType: keyof typeof PRODUCT_CONDITION_TYPE;
    usage: Confirmation.YES | Confirmation.NO;
  }) => {
    if (isErrorMessageShown.value) {
      isSubmitting.value = true;
      return;
    }

    if (!(await checkProductPermission())) {
      return;
    }

    const precessionList = (precessionProductNoList.value || []).map((productNo: string) => {
      return {
        precessionProductNo: Number(productNo)
      };
    });

    const request: CreatePrecessionRequest = {
      precessionType: value.precessionType,
      liveYn: value.usage,
      precessionList
    };

    if (product?.value?.planStatus === PLAN_STATUS.RELEASE) {
      const result = await showDialog({
        component: shallowRef(ReleaseDialog)
      });

      if (!result) {
        return;
      }

      saveData(request);

      return;
    }

    saveData(request);
  }
);

const saveData = async (request: CreatePrecessionRequest) => {
  try {
    if (isEditMode.value) {
      await updatePrecessionApi(productNo.value, request);
    } else {
      await createPrecessionApi(productNo.value, request);
    }

    await showCompleteSaveDialog();
    isEditMode.value = true;
  } catch (err) {}
};

const loadMore = async () => {
  canLoadMore.value = currentPage.value < totalPage.value;

  if (!isProductAvailable.value || !canLoadMore.value) {
    return;
  }
  currentPage.value += 1;

  if (canLoadMore.value) {
    const results = await searchProductsPrecessionApi(Number(productNo.value), {
      groupId: groupId.value,
      page: currentPage.value,
      planStatus: [PLAN_STATUS.RELEASE],
      productDetailType: `${PRODUCT_DETAIL_TYPE.BASIC},${PRODUCT_DETAIL_TYPE.SOUND},${PRODUCT_DETAIL_TYPE.IMAGE},${PRODUCT_DETAIL_TYPE.VIDEO},${PRODUCT_DETAIL_TYPE.DLC}`,
      demoYn: Confirmation.NO
    });

    if (!results) {
      canLoadMore.value = false;
      return;
    }

    const notIncluded = results.contents.filter(
      (item: ProductSearchItem) =>
        !precessionSearchResults.value.find(
          (i: ProductSearchItem) => i.productNo === item.productNo
        )
    );

    precessionSearchResults.value = precessionSearchResults.value.concat(notIncluded);
  }
};

const onRemove = (e: string) => {
  labelList.value = labelList.value.filter((item: FormOption<string>) => item.value !== e);
};

const onCheckboxClick = (e: boolean, productNo: string | number | boolean) => {
  if (!e) {
    labelList.value = labelList.value.filter(
      (item: FormOption<string>) => item.value !== productNo
    );
  }
};

// Set the correct label for all the precessions that are set for the current product
// The precessionSearchResults fetch 10 items at a time, but the current product's precessions maybe are on another page so we need to fetch the product info for each precession in advance
const setSelectedLabels = (precessionDetails: PrecessionDetails[]) => {
  precessionDetails.forEach(async (precession: PrecessionDetails) => {
    const productInfo = await fetchProductsInfoApi(
      precession.precessionProductNo.toString(),
      false
    );

    if (!productInfo) {
      return;
    }

    const label: string =
      productInfo.productType !== COLLECTION_PRODUCT_TYPE.COLLECTION
        ? `${precession.projectName} - ${precession.productName} (${precession.precessionProductNo})`
        : `${precession.productName} (${precession.precessionProductNo})`;

    const includedLabel = labelList.value.find((l: FormOption<string>) => l.label === label);
    if (!includedLabel) {
      labelList.value.push({
        label,
        value: precession.precessionProductNo.toString()
      });

      labelList.value = labelList.value.sort(
        (a: FormOption<string>, b: FormOption<string>) => Number(a.value) - Number(b.value)
      );
    }
  });
};

const init = async () => {
  const precessionSearchRes = await searchProductsPrecessionApi(Number(productNo.value), {
    groupId: groupId.value,
    planStatus: [PLAN_STATUS.RELEASE],
    productDetailType: `${PRODUCT_DETAIL_TYPE.BASIC},${PRODUCT_DETAIL_TYPE.SOUND},${PRODUCT_DETAIL_TYPE.IMAGE},${PRODUCT_DETAIL_TYPE.VIDEO},${PRODUCT_DETAIL_TYPE.DLC}`,
    demoYn: Confirmation.NO
  });

  if (precessionSearchRes) {
    precessionSearchResults.value = precessionSearchRes.contents;
    totalPage.value = precessionSearchRes.totalPages;
    isProductAvailable.value = precessionSearchResults.value.length !== 0;
  }

  try {
    const precessionRes = await fetchPrecessionApi(productNo.value);
    const precessionDetails = precessionRes?.precessionList ?? [];

    setSelectedLabels(precessionDetails);

    if (precessionRes) {
      precession.value = precessionRes;
      setValues({
        precessionType: precessionRes.precessionType,
        usage: precessionRes.liveYn
      });
    }

    isEditMode.value = true;
    if (precessionDetails && precessionDetails.length > 0) {
      precessionList.value = precessionDetails;

      setValues({
        precessionProductNoList: precessionList.value.map((item: PrecessionDetails) =>
          item.precessionProductNo.toString()
        )
      });
    }
  } catch (err) {}
};

init();

watch(
  () => precessionProductNoList.value,
  (value: string[]) => {
    if (value.length > 0) {
      isSubmitting.value = false;
    }

    conditionOptions.value.forEach((cond: FormOptionGroup) => {
      if (cond.value === PRODUCT_CONDITION_TYPE.AND) {
        cond.isDisabled = precessionProductNoList.value.length <= 1;
      } else {
        cond.isDisabled = true;
      }
    });
    if (precessionProductNoList.value.length <= 1) {
      setConditionType(PRODUCT_CONDITION_TYPE.AND);
    }
  }
);
onBeforeRouteLeave(async () => {
  return await showConfirmLeaveDialog();
});
</script>
